.ModaleAttentePaiementTPE-Container {
  align-items: center;
  display:flex;
  flex:1;
  flex-direction: column;
  padding: 20px;
}

.ModaleAttentePaiementTPE-TextContainer {
  display: flex;
   flex: 1;
}

.Animated {
  animation: shake 5s linear 2s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  2%,
  18% {
    transform: translate3d(-1px, 0, 0);
  }
  4%,
  16% {
    transform: translate3d(2px, 0, 0);
  }
  6%,
  10%,
  14% {
    transform: translate3d(-4px, 0, 0);
  }
  8%,
  12% {
    transform: translate3d(4px, 0, 0);
  }
}
