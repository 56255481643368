.Distribution-Container {
  align-items: center;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  justify-content: center;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.Distribution-ScrollbarContainer {
  bottom: 40px;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.Distribution-ScrollbarContainer .mdc-list {
  padding-top: 0px;
}
