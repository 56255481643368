.TicketClientModale-ButtonContainer {
  position : absolute;
  bottom: 10px;
  justify-content: center;
  display:flex;
}

.TicketClientModale-Container {
  align-items: center;
  display:flex;
  flex:1;
  flex-direction: column;
  justify-content: space-around;
}

.TicketClientModale-link {
  text-decoration: none;
}

