.Modale-Button-Container {
  position:absolute;
  right:7px;
  top:7px
}

.Modale-Container {
height:100%;
display: flex;
flex-direction: column;
}

.Modale-Title-Container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:5px;
  height:50px;
  font-size: 20px;
  border-bottom: 1px solid #fab001 ;
}

