.App-Container {
  bottom: 0px;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.App-Content {
  bottom: 0px;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 60px;
}

.mdc-button__ripple {
  background-color: #fab001;
}

.mdc-button__label {
  color: black;
  z-index: 0;
}

.Container-Display-None {
  display: none !important;
}
